//  state
const state = () => ({
    accountsOptions: [],
    accountIds: []
})

// getters
const getters = {
    formatAccountIds: (state, getters) => {
        let arr = state.accountIds.map(item => item[1])
        return arr
    },
}

// mutations
const mutations = {
    SETACCOUNTSOPTIONS(state, {
        accountsOptions
    }) {
        state.accountsOptions = [...accountsOptions]
    },
    SETACCOUNTIDS(state, {
        accountIds
    }) {
        state.accountIds = [...accountIds]
    }
}

// actions
const actions = {
    setAccountsOptions({
        commit
    }, {
        accountsOptions
    }) {
        commit('SETACCOUNTSOPTIONS', {
            accountsOptions
        })
    },
    setAccountIds({
        commit
    }, {
        accountIds
    }) {
        commit('SETACCOUNTIDS', {
            accountIds
        })
        // 存储
        localStorage.setItem("accountIds", JSON.stringify(accountIds));
    },

}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}