const state = {
    activeTab:'campaign',
    selectCampaignsOld: null,
    selectCampaignsNew: null,
    selectAdsetsOld: { campaign: null, adset: null},
    selectAdsetsNew: null,
    selectMultiCampaignsOld: null,
    selectMultiCampaignsNew: null,
    selectMultiAdsetsOld: { campaign: null, adset: null},
    selectMultiAdsetsNew: null,
    campaignDateRangOld: null,
    campaignDateRangNew:null,
    adsetDateRangNew: null,
    adsetDateRangOld: null,
    adDateRangNew: null,
    adDateRangOld:null,
    labelList:[]
}

const mutations = {
    setActiveTab: (state, activeTab) => {
        // console.log(activeTab);
        state.activeTab = activeTab
        // console.log(state.activeTab);
    },
    setSelectCampaignNew(state,v){
        // state.selectCampaignsOld = JSON.parse(JSON.stringify(state.selectCampaignsNew))
        state.selectCampaignsNew = v;
    },
    setSelectAdsetsNew(state, v) {
        // state.selectAdsetsOld = JSON.parse(JSON.stringify(state.selectAdsetsNew))
        state.selectAdsetsNew = v;
    },
    setSelectCampaignOld(state, v) {
        // state.selectCampaignsOld = JSON.parse(JSON.stringify(state.selectCampaignsNew))
        state.selectCampaignsOld = v;
    },
    setSelectAdsetsOld(state, v) {
        // state.selectAdsetsOld = JSON.parse(JSON.stringify(state.selectAdsetsNew))
        state.selectAdsetsOld = {...v};
    },
    setMultiSelectCampaignNew(state,v){
        // state.selectCampaignsOld = JSON.parse(JSON.stringify(state.selectCampaignsNew))
        state.selectMultiCampaignsNew = v;
    },
    setMultiSelectAdsetsNew(state, v) {
        // state.selectAdsetsOld = JSON.parse(JSON.stringify(state.selectAdsetsNew))
        state.selectMultiAdsetsNew = v;
    },
    setMultiSelectCampaignOld(state, v) {
        // state.selectCampaignsOld = JSON.parse(JSON.stringify(state.selectCampaignsNew))
        state.selectMultiCampaignsOld = v;
    },
    setMultiSelectAdsetsOld(state, v) {
        // state.selectAdsetsOld = JSON.parse(JSON.stringify(state.selectAdsetsNew))
        state.selectMultiAdsetsOld = {...v};
    },
    setCampaignDateRangNew(state,v){
        state.campaignDateRangNew = {...v}
    },
    setCampaignDateRangOld(state, v) {
        state.campaignDateRangOld = { ...v }
    },
    setAdsetDateRangNew(state, v) {
        state.adsetDateRangNew = { ...v }
    },
    setAdsetDateRangOld(state, v) {
        state.adsetDateRangOld = { ...v }
    },
    setAdDateRangNew(state, v) {
        state.adDateRangNew = { ...v }
    },
    setAdDateRangOld(state, v) {
        state.adDateRangOld = { ...v }
    },
    setCampaignLabel(state,v){
        state.labelList = [...v]
    }
}
const getters = {
    selectCampaignsOld: state => {
        return state.selectCampaignsOld
    },
    selectCampaignsNew: state => {
        return state.selectCampaignsNew
    },
    selectAdsetsOld: state => {
        return state.selectAdsetsOld
    },
    selectAdsetsNew: state => {
        return state.selectAdsetsNew
    },
    campaignDateRangOld:state=>{
        return state.campaignDateRangOld
    },
    campaignDateRangNew: state => {
        return state.campaignDateRangNew
    },
    adsetDateRangNew: state => {
        return state.adsetDateRangNew
    },
    adsetDateRangOld: state => {
        return state.adsetDateRangOld
    },
    adDateRangOld: state => {
        return state.adDateRangOld
    },
    adDateRangNew: state => {
        return state.adDateRangNew
    },
    labelList:state=>{
        return state.labelList
    }
}
const actions = {

}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}