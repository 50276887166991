const state = {
  initData: null,
  currentSelect: [],
  baseData: [],
  googleValid: true, //谷歌校验是否通过
  constDataGoogle: {}, //谷歌常量
  adTypeData: [
    { key: 'DISCOVERY', value: '发现广告', icon: 'el-icon-picture-outline' },
    { key: 'VIDEO', value: '视频广告', icon: 'el-icon-video-camera' },
  ], //广告类型
  targetCpData: [{ label: '目标每次转化费用', value: '1' }], //广告组出价
  accountID: '1509393231', //google账户id
  googleValidName: true, //谷歌校验广告名字是否通过
  allUseAdcounts: [], //所有广告账户
  adAccountLabelList: [], //根据标签分组的账户
  campaignMsg: {
    loadTable: false,
    campaignId: '',
    changeAccount: true,
  },
};

const mutations = {
  setAllUseAdcounts: (state, data) => {
    state.allUseAdcounts = data;
  },
  setAdAccountLabelList: (state, data) => {
    state.adAccountLabelList = data;
  },
  setInitData: (state, data) => {
    // console.log(activeTab);
    state.initData = data;
    // console.log(state.activeTab);
  },
  setCurrentSelect: (state, select) => {
    state.currentSelect = select;
  },
  setBaseData: (state, baseData) => {
    state.baseData = baseData;
  },
  setGoogleValidName: (state, googleValidName) => {
    state.googleValidName = googleValidName;
  },
  setGoogleValid: (state, googleValid) => {
    state.googleValid = googleValid;
  },
  setConstDataGoogle: (state, constDataGoogle) => {
    state.constDataGoogle = constDataGoogle;
  },
  setAccountIdGoogle: (state, accountID) => {
    state.accountID = accountID;
  },
  setCampaignMsg: (state, campaignMsg) => {
    state.campaignMsg = { ...campaignMsg };
  },
};
const getters = {
  getOptimizationGoals: (state, getters) => (objective) => {
    let optimizationGoal = state.initData.ad.CampaignObjective.find((v) => v.key == objective);
    return [optimizationGoal.default_optimizationGoal].concat(optimizationGoal.other_valid_optimizationGoal);
  },
  getAttributionSpec: (state, getters) => (optimizationGoal) => {
    let optimizationGoals = state.initData.ad.AdSetOptimizationGoal.find((v) => v.key == optimizationGoal);
    return optimizationGoals?.attributionSpecs || [];
  },
  getBidStrategyList: (state, getters) => (bidMsg) => {
    if (state.initData.ad && state.initData.ad.CampaignBidStrategy) {
      let list = state.initData.ad.CampaignBidStrategy;
      list = state.initData.ad.CampaignBidStrategy.filter((v) => v.objectives.some((k) => k.key == bidMsg.objective));
      if (bidMsg.optimization_goal !== 'VALUE') {
        list = list.filter((v) => v.key !== 'LOWEST_COST_WITH_MIN_ROAS');
      } else {
        list = list.filter((v) => v.key != 'COST_CAP' && v.key != 'LOWEST_COST_WITH_BID_CAP');
      }
      if (bidMsg.smart_promotion_type == 'AUTOMATED_SHOPPING_ADS') {
        list = list.filter((v) => v.key !== 'LOWEST_COST_WITH_BID_CAP');
      }
      return list;
    } else {
      return [];
    }
  },
};
const actions = {
  initData: (state) => {
    return state.initData;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
