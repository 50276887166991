const state = {
    activeTab: 'campaign',

    selCampaigns: [],
    selAdsets: [],
    selAds: []
}

const mutations = {
    SETSELCAMPAIGNS(state, {
        selCampaigns
    }) {
        state.selCampaigns = selCampaigns
    },
    SETSELADSETS(state, {
        selAdsets
    }) {
        state.selAdsets = selAdsets
    },
    SETSELADS(state, {
        selAds
    }) {
        state.selAds = selAds
    },

    setActiveTab: (state, activeTab) => {
        // console.log(activeTab);
        state.activeTab = activeTab
        // console.log(state.activeTab);
    },
}
const getters = {
}
const actions = {
    setSelCampaigns({ commit }, { selCampaigns }) {
        commit('SETSELCAMPAIGNS', {
            selCampaigns
        })
    },
    setSelAdsets({ commit }, { selAdsets }) {
        commit('SETSELADSETS', {
            selAdsets
        })
    },
    setSelAds({ commit }, { selAds }) {
        commit('SETSELADS', {
            selAds
        })
    }
}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}