
const state = {
  userInfo: {},
}

const mutations = {
  SETUSERINFO: (state, userInfo) => {
    state.userInfo = {...userInfo}
  }
}

const actions = {
  
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}

