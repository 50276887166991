const state = {
  baseUrl: 'https://fbapi.giikin.com/adreport/',
  adsConsts: null, // 枚举
};
const getters = {
  adsConsts(state) {
    return state.adsConsts || {};
  },
};
const mutations = {
  setAdsConsts(state, data) {
    state.adsConsts = { ...data };
  },
};

const actions = {};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
