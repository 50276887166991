const getters = {
  sidebar: (state) => state.app.sidebar,
  showSideBar: (state) => state.app.showSideBar,
  accountId: (state) => state.num.activeAdAccount.id,
  fbAccountId: (state) => state.num.activeAdAccount.id,
  currentAccountInfo: (state) => state.num.activeAdAccount,
  accountsList: (state) => state.num.adAccountsLists,
  toFormatedMoney: (state, gets) => gets['num/toFormatedMoney'],
  toBaseMoney: (state, gets) => gets['num/toBaseMoney'],
  toFormatedRoas: (state, gets) => gets['num/toFormatedRoas'],
  toBaseRoas: (state, gets) => gets['num/toBaseRoas'],
  currentUiUnit: (state, gets) => gets['num/currentUiUnit'],
  hideNavbarAccount: (state) => state.app.hideNavbarAccount,
  token: (state) => state.user.userInfo.data.token,
  adDateRange: (state) => state.adsDate.adDateRange,
  emojiList: (state) => (name) => state.adsEmoji.emojiList.filter((v) => v.name === name)[0],
  emojiNameList: (state) =>
    state.adsEmoji.emojiList.map((v) => ({
      name: v.name,
    })),
  adActiveTab: (state) => state.adActiveTab.activeTab,
  batchAdActiveTab: (state) => state.batchAdActiveTab.activeTab,
  initData: (state) => state.initData.initData,
  currentSelect: (state) => state.initData.currentSelect,
  baseData: (state) => state.initData.baseData,
  adsConsts: (state) => state.base.adsConsts,
  userInfo: (state) => state.user.userInfo.data,
};
export default getters;
