//  state
const state = () => ({
  isSync: false,
  selectedNum: '',
  numLists: [],
  adAccountsAllDatas: {},
  adAccountsLists: [],
  activeAdAccount: {},
  multiAccountList: [],
  selectNumLable: null,
  currentAddSaleId: '', // 当前创建广告的商品id
  audiencesNeedData: {}, // 存为常用 受众所需数据
});

// getters
const getters = {
  validNumLists: (state, getters) => {
    // 有效的个号
    return state.numLists.filter((item) => item.status == 'VALID');
  },
  defNum: (state, getters) => {
    if (getters.validNumLists.length) {
      return getters.validNumLists.find((item) => item.defaultLogin); //defaultLogin
    }
  },
  hasActiveAdAccountsLists: (state, getters) => {
    if (state.adAccountsLists.length && state.activeAdAccount.id) {
      // console.log("state.activeAdAccount", state.activeAdAccount)
      let index = state.adAccountsLists.findIndex((item) => item.id == state.activeAdAccount.id);
      let arr = [...state.adAccountsLists];
      arr.forEach((item) => {
        item.active = false;
      });
      if (index != -1) {
        arr[index].active = true;
      }
      // console.log("hasActiveAdAccountsLists", arr)
      return arr;
    }
  },
  currentUiUnit: (state) => {
    if (state.activeAdAccount.currency) {
      return state.activeAdAccount.currency.uiUnit;
    }
    return {};
  },
  toFormatedMoney: (state, getters) => (amount, notBase) => {
    let uiUnit = getters.currentUiUnit || {};
    let v = (parseFloat(amount) / (uiUnit?.multiToBase ?? 1)).toFixed(2);
    if (notBase) {
      v = parseFloat(amount).toFixed(2);
    }
    return {
      formated: uiUnit.symbol ? uiUnit.symbol + v : '$' + v,
      unit: uiUnit.name,
      value: parseFloat(v),
      symbol: uiUnit.symbol,
    };
  },
  toFormatedMoneyV2: (state, getters) => (amount, notBase) => {
    let _amount = amount ? amount : 0;
    let uiUnit = getters.currentUiUnit;
    let v = (parseFloat(_amount) / uiUnit.multiToBase).toFixed(2);
    if (notBase) {
      v = parseFloat(_amount).toFixed(2);
    }
    return {
      formated: uiUnit.symbol ? uiUnit.symbol + v : '$' + v,
      unit: uiUnit.name,
      value: parseFloat(v),
      symbol: uiUnit.symbol,
    };
  },
  toBaseMoney: (state, getters) => (amount) => {
    let uiUnit = getters.currentUiUnit || {};
    var pattern1 = new RegExp('^(\\d+\\.?\\d*)$ ', 'gi');
    let arr = ('' + amount).match(pattern1);
    let amount1 = amount;
    if (arr) {
      amount1 = arr[1];
    }
    let v = (parseFloat(amount1) * (uiUnit?.multiToBase ?? 1)).toFixed(0);
    return parseInt(v);
  },
  toFormatedRoas: () => (val) => {
    return val / 10000;
  },
  toBaseRoas: () => (val) => {
    return val * 10000;
  },
};

// mutations
const mutations = {
  SETISSYNC(state, { isSync }) {
    state.isSync = isSync;
  },
  SETSELECTEDNUM(state, { selectedNum }) {
    state.selectedNum = selectedNum;
  },
  SETNUMLISTS(state, { numLists }) {
    state.numLists = numLists;
  },
  SETADACCOUNTSALLDATAS(state, { allDatas }) {
    state.adAccountsAllDatas = allDatas;
  },
  SETADACCOUNTSLISTS(state, { adAccountsLists }) {
    state.adAccountsLists = adAccountsLists;
  },
  SETACTIVEADACCOUNT(state, { activeAdAccount }) {
    state.activeAdAccount = activeAdAccount;
  },
  SETMULTIACCOUNTLIST: (state, multiAccountList) => {
    state.multiAccountList = [...multiAccountList];
  },
  selectNumLable: (state, selectNumLable) => {
    state.selectNumLable = selectNumLable;
    localStorage.setItem('selectNumLable', selectNumLable);
    sessionStorage.setItem('selectNumLable', selectNumLable);
  },
  SET_CURRENT_ADD_SALE_ID(state, message) {
    // 设置商品id
    state.currentAddSaleId = message;
  },
  SET_AUDIENCES_NEED_DATA(state, message) {
    // 设置 存为常用 受众所需数据
    state.audiencesNeedData = message;
  },
};

// actions
const actions = {
  setIsSync({ commit }, { isSync }) {
    commit('SETISSYNC', {
      isSync,
    });
  },
  setSelectedNum({ commit }, { selectedNum }) {
    commit('SETSELECTEDNUM', {
      selectedNum,
    });
    // 存储
    localStorage.setItem('selectedNum', selectedNum);
    sessionStorage.setItem('selectedNum', selectedNum);
    window.selectedNum = selectedNum;
  },
  setNumLists({ commit }, { numLists }) {
    commit('SETNUMLISTS', {
      numLists,
    });
  },
  setAdAccountsAllDatas({ commit }, { allDatas }) {
    commit('SETADACCOUNTSALLDATAS', {
      allDatas,
    });
  },
  setAdAccountsLists({ commit }, { adAccountsLists }) {
    commit('SETADACCOUNTSLISTS', {
      adAccountsLists,
    });
  },
  setActiveAdAccount({ commit }, { activeAdAccount }) {
    if (activeAdAccount) {
      commit('SETACTIVEADACCOUNT', {
        activeAdAccount,
      });
      // 存储
      localStorage.setItem('activeAdAccount', JSON.stringify(activeAdAccount));
      sessionStorage.setItem('activeAdAccount', JSON.stringify(activeAdAccount));

      window.activeAdAccount = activeAdAccount;
    }
  },
  setMultiAccountList({ commit }, { multiAccountList }) {
    commit('SETMULTIACCOUNTLIST', {
      multiAccountList,
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
