const state = {
    adDateRange:{
        actionAttributionWindows: [],
        dateRange: []
    }
}

const mutations = {
    setDateRange: (state, adDateRange) => {
        // console.log(adDateRange);
        state.adDateRange = { ...adDateRange}
        // console.log(state.adDateRange);
    },
}
const getters = {
    adDateRange: state => {
        return state.adDateRange
    },
}


export default {
    namespaced: true,
    state,
    mutations,
    getters
}