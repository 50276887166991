import Vue from 'vue'
import Vuex from 'vuex'
import getters from './getters'
import app from './modules/app'
import user from './modules/user'
import num from './modules/num'
import batchAccount from './modules/batchAccount'
import batchAdActiveTab from './modules/batchAdActiveTab'
import adsDate from './modules/adsDate'
import adsEmoji from './modules/adsEmoji'
import adActiveTab from './modules/adActiveTab'
import initData from './modules/initData'
import chartDrawer from './modules/chartDrawer'
import autoRulesResult from "./modules/autoRulesResult"
import audiences from "./modules/audiences";
import base from "./modules/base";
import creativeData from "./modules/creativeData";

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    app,
    user,
    num,
    batchAccount,
    batchAdActiveTab,
    adsDate,
    adsEmoji,
    adActiveTab,
    chartDrawer,
    initData,
    autoRulesResult,
    audiences,
    base,
    creativeData
  },
  getters
})

export default store