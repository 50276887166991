import Cookies from 'js-cookie'

const state = {
  sidebar: {
    // opened: Cookies.get('sidebarStatus') ? !!+Cookies.get('sidebarStatus') : true
    opened: true
  },
  hideNavbarAccount: true,
}

const mutations = {
  TOGGLE_SIDEBAR: state => {
    state.sidebar.opened = !state.sidebar.opened
    // if (state.sidebar.opened) {
    //   Cookies.set('sidebarStatus', 1)
    // } else {
    //   Cookies.set('sidebarStatus', 0)
    // }
  },
  OPEN_SIDEBAR: (state) => {
    // Cookies.set('sidebarStatus', 1)
    state.sidebar.opened = true
  },
  CLOSE_SIDEBAR: (state) => {
    // Cookies.set('sidebarStatus', 0)
    state.sidebar.opened = false
  },
  SETSIDEBAR: (state,val) => {
    state.sidebar.opened=val
  },
  HIDENAVBARACCOUNT: (state, hideNavbarAccount) => {
    state.hideNavbarAccount = hideNavbarAccount
  }
}

const actions = {
  toggleSideBar({
    commit
  }) {
    commit('TOGGLE_SIDEBAR')
  },
  openSideBar({
    commit
  }) {
    commit('OPEN_SIDEBAR')
  },
  closeSideBar({
    commit
  }) {
    commit('CLOSE_SIDEBAR')
  },
  setSideBar({
    commit
  },val) {
    commit('SETSIDEBAR',val)
  },
  hideNavbarAccount({
    commit
  }, payload) {
    commit(('HIDENAVBARACCOUNT'), payload.hideNavbarAccount)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}