import {
    getAlertNames,
    getQueryItems,
    getReport,
    pricingInfo
} from "@/api/autoRulesResult";
import { Message, MessageBox } from "element-ui";

const state = {
    formSelects: [],
    showResult: false,
    tableData: [],
    mobileFilterList: [],
    alertNames: [],
    currentPage: 1,
    pageSizes: [10, 20, 30, 50, 100],
    pageSize: 10,
    total: 0,
    detail: {},
    batchNos: [],
    pricingForm: { execution: {} },
    showPricing: false,
    tem:{},
    mobileTemIndex: -1,
    typeFilterList: []
}

const getters = {
    getSelects: state => state.formSelects,
    isShowResult: state => state.showResult,
    getAlertNames: state => state.alertNames
}

const mutations = {
    isShowResult(state, isShow) {
        state.showResult = isShow
    },
    setSelects(state, list) {
        state.formSelects = list
    },
    setTableData(state, list) {
        state.tableData = list
    },
    setMobileFilterList(state, list) {
        state.mobileFilterList = list;
    },
    setAlertNames(state, list) {
        state.alertNames = list
    },
    changeCurrentPage(state, page) {
        state.currentPage = page
    },
    changePageSize(state, size) {
        state.pageSize = size
    },
    setTotal(state, total) {
        state.total = total
    },
    setDetail(state, detail) {
        state.detail = detail
    },
    setBatchNos(state, batchNos) {
        state.batchNos = batchNos
    },
    setPricingForm(state, form) {
        state.pricingForm = form
    },
    isShowPricing(state, isShow) {
        state.showPricing = isShow
    },
    setTem(state, tem) {
        state.tem = tem;
    },
    setMobileTemIndex(state, index) {
        state.mobileTemIndex = index
    },
    setTypeFilterList(state, list) {
        state.typeFilterList = list
    }
}

const actions = {
    getSelects({ commit }) {
        getAlertNames().then(res => {
            commit("setSelects", res.data)
        })
    },
    getTableData({ rootState, state, commit }) {
        let userId = rootState.user.userInfo.data.id;
        let batchNos = state.batchNos;
        let data = {
            userId,
            queryLevel: "top",
            batchNos
        }
        getQueryItems(data).then(res => {
            // console.log(res);
            if (res.code == 0) {
                res.data.map(v => {
                    v.id = v.id || v.objId;
                    v.typeCounts = v.typeCounts || [];
                    var count = 0;
                    v.typeCounts.map(ty => {
                        if (ty.type == "CHANGE_BUDGET" || ty.type == "CHANGE_CAMPAIGN_BUDGET") {
                            count += ty.count
                        }
                    })
                    v.typeCounts = v.typeCounts.filter(ty => !(ty.type == "CHANGE_BUDGET" || ty.type == "CHANGE_CAMPAIGN_BUDGET"))
                    if (count) {
                        v.typeCounts.push({
                            type: "CHANGE_BUDGET",
                            count
                        })
                    }
                })
                commit("setTableData", res.data);
            }
        })
    },
    getDetail({ commit }, opt) {
        var date_start;
        console.log(opt);
        if (opt.createTime) {
            date_start = getDate(opt.createdTime)
        } else {
            date_start = opt.alertName ? `${opt.alertName.substr(0,4)}-${opt.alertName.substr(4,2)}-${opt.alertName.substr(6,2)}` : getDate()
        }
        var data = {
            "level": opt.objType,
            "page_length": 1,
            "date_start": date_start,
            "date_end": getDate(),
            "filtering": [],
            "account_ids": [opt.accountId],
            "keyword": opt.objId,
            "fields": ["product_report", "impressions", "unique_clicks", "ctr", "purchase_cost", "purchase_roas", "purchase_rate", "spend", "cpm"],
            "action_attribution_windows": [],
            "object_filtering": [],
            "sort": [],
        }
        getReport(data).then(res => {
            console.log(res);
            if (res.code == 0) {
                commit("setDetail", res.data.data);
                commit("isShowResult", true)
            }
        })
    },
    getPricingForm({ commit }, {opt,tem = {}}) {
        pricingInfo({
            ids:[opt.objId],
            level:opt.objType
        }).then(res=>{
            console.log(res);
            if (res.code == 0 && res.data.length) {
                commit("setTem", tem)
                let data = res.data[0];
                const execution = opt.executionSpec.executionOptions[0];
                const form = {
                    name: opt.objName,
                    id: opt.objId,
                    level: opt.objType,
                    execution: JSON.parse(execution.value)
                }
                if (!data.daily_budget && !data.lifetime_budget) {
                    if (opt.level == 'adset') {
                        Message.error("无法在广告组修改广告系列预算")
                    } else {
                        Message.error("无法在广告系列修改广告组预算")
                    }
                } else {
                    if( data.daily_budget ){
                        form.amount = data.daily_budget/100;
                        form.amountType = "daily_budget";
                    } else {
                        form.amount = data.lifetime_budget/100;
                        form.amountType = "lifetime_budget";
                    }
                    form.subAmount = getAmount(form);
                    commit("setPricingForm", form);
                    commit("isShowPricing", true);
                }
            }
        })
    }
}

function getAmount(data){
    if(data.execution.unit == "ACCOUNT_CURRENCY"){
        return data.amount + data.execution.amount
    } else {
        return data.amount + (data.amount * data.execution.amount / 100)
    }
}

function getDate(time) {
    var date = time ? new Date(time) : new Date();
    var year = date.getFullYear();
    var month = (date.getMonth() + 1);
    month = month < 10 ? "0" + month : month;
    var day = date.getDate();
    day = day < 10 ? "0" + day : day;
    return `${year}-${month}-${day}`
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}