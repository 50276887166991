const state = {
    multipleAdsets: []
}

const mutations = {
    setMultipleAdsets(state, list) {
        state.multipleAdsets = list
    }
}


export default {
    namespaced: true,
    state,
    mutations
}