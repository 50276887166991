const state = {
    copywriteDefault:{copywrite:{title:'',content:''},message:''},
}

const mutations = {
    setCopywriteDefault: (state, data) => {
        state.copywriteDefault = data
    }
}

const actions = {

}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}