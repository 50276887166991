import emojiList from "../../views/adManagement/components/emoji/icon";
const state = {
    emojiList,
}

const mutations = {
    setEmojiList: (state, list) => {
        state.emojiList = list
    }
}

const actions = {

}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}