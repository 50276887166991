import {
    setDisabledFields
} from "@/views/adManagement/components/chartDrawer/tool"

//  state
const state = () => ({
    colors: ["#409eff", "#67c23a", "rgba(0, 0, 0, 0.85)", "rgb(157, 160, 163)"],
    requiredFields: ["id", "name", "status"],
    fields: [
        {
            "filtering": [
                {
                    "desc": "等于",
                    "key": "EQUAL"
                },
                {
                    "desc": "不等于",
                    "key": "NOT_EQUAL"
                },
                {
                    "desc": "大于",
                    "key": "GREATER_THAN"
                },
                {
                    "desc": "小于",
                    "key": "LESS_THAN"
                }
            ],
            "columnGroup": {
                "title": "基础数据",
                "id": "BASE"
            },
            "hasSort": true,
            "indicatorType": "T3",
            "defaultColumn": true,
            "fixed": false,
            "keyName": "spend",
            "name": "花费",
            "remark": null,
            "type": "MONEY",
            "width": 83,
            "appMust": null,
            "enumValues": []
        },
        {
            "filtering": [],
            "columnGroup": {
                "title": "常用转化",
                "id": "CONVERTIONS"
            },
            "hasSort": true,
            "indicatorType": "T3",
            "defaultColumn": false,
            "fixed": false,
            "keyName": "purchase_web",
            "name": "购买(Web)",
            "remark": null,
            "type": "INT",
            "width": 80,
            "appMust": null,
            "enumValues": []
        },
        {
            "filtering": [],
            "columnGroup": {
                "title": "常用转化",
                "id": "CONVERTIONS"
            },
            "hasSort": false,
            "indicatorType": "T1",
            "defaultColumn": false,
            "fixed": false,
            "keyName": "purchase_web_cost",
            "name": "购买成本(Web)",
            "remark": "产生一个购买(Web)事件的成本，花费/购买(Web)",
            "type": "MONEY",
            "width": 110,
            "appMust": null,
            "enumValues": []
        },
        {
            "filtering": [],
            "columnGroup": {
                "title": "常用转化",
                "id": "CONVERTIONS"
            },
            "hasSort": false,
            "indicatorType": "T3",
            "defaultColumn": false,
            "fixed": false,
            "keyName": "add_to_cart",
            "name": "加入购物车",
            "remark": null,
            "type": "INT",
            "width": 94,
            "appMust": true,
            "enumValues": []
        },
        {
            "filtering": [],
            "columnGroup": {
                "title": "常用转化",
                "id": "CONVERTIONS"
            },
            "hasSort": false,
            "indicatorType": "T1",
            "defaultColumn": false,
            "fixed": false,
            "keyName": "add_to_cart_web_cost",
            "name": "加入购物车成本(Web)",
            "remark": "产生一个加入购物车(Web)事件的成本，花费/加入购物车(Web)",
            "type": "MONEY",
            "width": 147,
            "appMust": null,
            "enumValues": []
        },
        {
            "filtering": [],
            "columnGroup": {
                "title": "其他",
                "id": "OTHER"
            },
            "hasSort": false,
            "indicatorType": "T3",
            "defaultColumn": false,
            "fixed": false,
            "keyName": "link_click",
            "name": "链接点击量",
            "remark": null,
            "type": "INT",
            "width": 97,
            "appMust": null,
            "enumValues": []
        },
        {
            "filtering": [],
            "columnGroup": {
                "title": "其他",
                "id": "OTHER"
            },
            "hasSort": true,
            "indicatorType": "T1",
            "defaultColumn": false,
            "fixed": false,
            "keyName": "cpc",
            "name": "点击成本",
            "remark": "每次点击（全部）的平均费用",
            "type": "MONEY",
            "width": 100,
            "appMust": true,
            "enumValues": []
        },
        {
            "filtering": [],
            "columnGroup": {
                "title": "其他",
                "id": "OTHER"
            },
            "hasSort": true,
            "indicatorType": "T1",
            "defaultColumn": false,
            "fixed": false,
            "keyName": "ctr",
            "name": "点击率",
            "remark": "看到广告并产生点击（全部）的百分比，点击量（全部）/展示次数",
            "type": "PERCENTAGE",
            "width": 98,
            "appMust": true,
            "enumValues": []
        },
        {
            "filtering": [
                {
                    "desc": "等于",
                    "key": "EQUAL"
                },
                {
                    "desc": "不等于",
                    "key": "NOT_EQUAL"
                },
                {
                    "desc": "大于",
                    "key": "GREATER_THAN"
                },
                {
                    "desc": "小于",
                    "key": "LESS_THAN"
                }
            ],
            "columnGroup": {
                "title": "基础数据",
                "id": "BASE"
            },
            "hasSort": true,
            "indicatorType": "T1",
            "defaultColumn": false,
            "fixed": false,
            "keyName": "cpm",
            "name": "千次",
            "remark": "千次展示费用(Cost Per Mille)。投放的总花费除以展示次数，再乘以1000",
            "type": "MONEY",
            "width": 66,
            "appMust": true,
            "enumValues": []
        },
        {
            "filtering": [
                {
                    "desc": "等于",
                    "key": "EQUAL"
                },
                {
                    "desc": "不等于",
                    "key": "NOT_EQUAL"
                },
                {
                    "desc": "大于",
                    "key": "GREATER_THAN"
                },
                {
                    "desc": "小于",
                    "key": "LESS_THAN"
                }
            ],
            "columnGroup": {
                "title": "基础数据",
                "id": "BASE"
            },
            "hasSort": true,
            "indicatorType": "T3",
            "defaultColumn": true,
            "fixed": false,
            "keyName": "impressions",
            "name": "展示次数",
            "remark": null,
            "type": "INT",
            "width": 107,
            "appMust": null,
            "enumValues": []
        }
],
    showFields: []
})

// getters
const getters = {
    filteredFields: (state, getters) => {
        // 过滤掉必填项
        let arr = []
        let fields = [...state.fields]
        arr = fields.filter(item =>
            state.requiredFields.every(a => a != item.keyName)
        )
        // 过滤掉设置分组和indicatorType为"T0"的字段
        let arr2 = arr.filter(
            item =>
            item.columnGroup.id != "SETTING" && item.indicatorType != "T0"
        )
        return JSON.parse(JSON.stringify(state.fields))
    },
    hasDisableFilteredFields: (state, getters) => {
        return setDisabledFields(state.showFields, getters.filteredFields)
    }
}

// mutations
const mutations = {
    SETFIELDS(state, {
        fields
    }) {
        state.fields = fields
    },
    SETSHOWFIELDS(state, {
        fields
    }) {
        state.showFields = fields
    },
}

// actions
const actions = {
    setFields({
        commit
    }, {
        fields
    }) {
        commit('SETFIELDS', {
            fields
        })
    },
    setShowFields({
        commit
    }, {
        fields
    }) {
        commit("SETSHOWFIELDS", {
            fields
        })
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}